import React, { FC, useState } from "react";
import {
  Stack,
  ButtonGroup,
  Button,
  Text,
  Box,
  Flex,
  useToast,
  Tooltip,
} from "@chakra-ui/react";
import { ChromePicker, ColorResult } from "react-color";
import { useColors } from "hooks/useColors";
import { rem } from "polished";
import { usePartnerStore } from "store/partner";
import { Formik } from "formik";
import { ColorEditorFormProps, ColorPickerProps } from "../types";
import { ColorInputValidation } from "@utils/validations/form";
import { useTranslation } from "react-i18next";
import { adminCreateColor, adminUpdateColor } from "services/api/partners";
import { QuestionOutlineIcon } from "@chakra-ui/icons";
import { groupBy, map } from "lodash";

const ColorPicker: FC<ColorPickerProps> = ({
  data,
  setFieldValue,
  view,
  setView,
}) => {
  const { brand } = usePartnerStore();
  const [color, setColor] = useState(data?.value);
  const handleChange = (color: ColorResult) => {
    setColor(color.hex);
    setFieldValue(data.id, { ...data, value: color.hex });
  };

  if (!data) return null;
  return (
    <Box my={2} position="relative">
      <Flex direction="row" alignItems="center" gap={4}>
        <Flex flex={2} overflow="hidden" direction="column">
          <Flex direction="row" alignItems="center" gap={4}>
            <Text
              fontSize={rem(14)}
              lineHeight={rem(18)}
              fontFamily={brand?.font_primary}
              color="black"
              noOfLines={1}
            >
              {data.title}
            </Text>
            {data.global && (
              <Tooltip
                bg="black"
                color="white"
                label={`This change will apply on all the other places on the funnel`}
                fontSize={rem(12)}
                p={4}
              >
                <QuestionOutlineIcon boxSize="0.7em" />
              </Tooltip>
            )}
          </Flex>
          <Text
            fontSize={rem(10)}
            lineHeight={rem(14)}
            fontFamily={brand?.font_primary}
            color="black"
            noOfLines={1}
          >
            ({data.key})
          </Text>
        </Flex>
        <Flex flex={1}>
          <Box
            cursor="pointer"
            onClick={() => setView(data.key === view ? null : data.key)}
            p={rem(5)}
            bg="white"
            borderRadius={rem(1)}
            boxShadow="0 0 0 1px rgba(0,0,0,.1)"
          >
            <Box
              width={rem(36)}
              height={rem(14)}
              borderRadius={rem(2)}
              bg={color}
              boxShadow="0 0 0 1px rgba(0,0,0,.1)"
            />
          </Box>
        </Flex>
        <Flex flex={1}>
          <Text
            cursor="pointer"
            fontSize={rem(12)}
            lineHeight={rem(18)}
            fontFamily={brand?.font_primary}
            color="black"
            onClick={() => setView(data.key === view ? null : data.key)}
          >
            {color}
          </Text>
        </Flex>
      </Flex>
      {data.key === view && (
        <Flex my={2} justifyContent="center">
          <ChromePicker disableAlpha color={color} onChange={handleChange} />
        </Flex>
      )}
    </Box>
  );
};

const ColorEditorForm: FC<ColorEditorFormProps> = ({ onClose, colors }) => {
  const colorGroups = groupBy(colors, "group");
  const { getColor } = useColors();
  const { t } = useTranslation();
  const { brand, getPartner } = usePartnerStore();
  const toast = useToast();
  const [view, setView] = useState<string | null>(null);
  return (
    <Formik
      initialValues={colors.reduce(
        (a, v) => ({ ...a, [v.id as string]: { ...v } }),
        {}
      )}
      validationSchema={ColorInputValidation(t, colors)}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          const updatedColors = map(
            values,
            (data: { key: string; value: string }, id) => ({
              ...data,
              id,
            })
          );
          if (updatedColors?.length) {
            // get new colors with key pairs
            const newColors = updatedColors.filter((color) =>
              color.id.includes("new-")
            );
            // get partner existing colors
            const existingColors = updatedColors.filter(
              (color) => !color.id.includes("new-")
            );
            const differences =
              existingColors.length &&
              existingColors.filter((updatedColor) =>
                colors.find(
                  (color) =>
                    updatedColor.id === color.id &&
                    updatedColor.value !== color.value
                )
              );
            if (differences && differences.length) {
              await adminUpdateColor(
                differences.map((color) => ({
                  id: color.id,
                  value: color.value,
                }))
              );
            }
            if (newColors?.length) {
              await Promise.all(
                newColors.map((color) =>
                  adminCreateColor({
                    key: color.key,
                    value: color.value,
                  })
                )
              );
            }
            await getPartner();
          }
        } catch (error) {
          toast({
            title: String(error),
            variant: "solid",
            isClosable: true,
            status: "warning",
          });
        } finally {
          setSubmitting(false);
          onClose();
        }
      }}
    >
      {({ submitForm, values, isSubmitting, setFieldValue }) => {
        return (
          <>
            <Stack spacing={4} my={4} maxH={rem(300)} overflowY="auto">
              {map(colorGroups, (group, index) => (
                <Flex direction="column" key={index}>
                  <Text
                    fontSize={rem(16)}
                    lineHeight={rem(20)}
                    fontWeight="bold"
                    fontFamily={brand?.font_primary}
                    color={getColor("title")}
                  >
                    {index}
                  </Text>
                  {group.map((colorItem) => (
                    <ColorPicker
                      key={colorItem.id}
                      {...{
                        setFieldValue,
                        data:
                          values && values[colorItem.id as keyof typeof values],
                        setView,
                        view,
                      }}
                    />
                  ))}
                </Flex>
              ))}
            </Stack>
            <ButtonGroup mt={2} display="flex" justifyContent="flex-end">
              <Button
                size="sm"
                rounded="full"
                variant="outline"
                onClick={onClose}
              >
                <span>{t("common.cancel")}</span>
              </Button>
              <Button
                size="sm"
                isDisabled={isSubmitting}
                isLoading={isSubmitting}
                rounded="full"
                variant="primary"
                onClick={() => submitForm()}
              >
                <span>{t("common.continue")}</span>
              </Button>
            </ButtonGroup>
          </>
        );
      }}
    </Formik>
  );
};

export default ColorEditorForm;
