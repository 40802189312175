import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import { StripeConstructor } from "@stripe/stripe-js";
import { configureSentry, configureNewRelic } from "services";
import { SentryBoundary } from "features/sentry";

configureSentry();
configureNewRelic();

declare global {
  interface Window {
    deviceAPI: {
      deviceType: string;
      deviceName: string;
      deviceBrand: string;
    };
    fod: any;
    Trengo: {
      key: string;
      render: boolean;
      on_close: () => void;
      on_ready: () => void;
      init: (param: any) => void;
      Api: {
        Widget: {
          open: (channelType: string) => void;
          render: () => void;
          url_updated: () => void;
        };
      };
    };
    Stripe?: StripeConstructor;
  }
}

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <Router>
      <SentryBoundary>
        <App />
      </SentryBoundary>
    </Router>
  </React.StrictMode>
);
