import { FC, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, ChakraProvider, extendTheme, useToast } from "@chakra-ui/react";
import { chakraTheme } from "alicia-design-system";
import Cookies from "universal-cookie";
import Routes from "./routes";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useAuthStore, usePartnerStore } from "./store";
import { useQueryParams } from "./hooks/useQueryParams";
import { useTitleStore } from "store/title";
import TagManager from "react-gtm-module";
import { InsuranceFlow, MainQueryParams, UserType } from "./types";
import { useTrengo } from "hooks/useTrengo";
import {
  isInsuranceCalculatorRoute,
  isInsuranceCheckInRoute,
} from "@utils/getFlowPaths";
import { useColors } from "hooks/useColors";
import { getEnvironment } from "@utils/getEnvironment";
import { useLanguageSelector } from "hooks/useLanguageSelector";
import { ADMIN_COOKIE_TOKEN } from "./constant";
import { getCookieURL } from "@utils/getCookieURL";
import { PostHog, PostHogProvider } from "posthog-js/react";
import { omit } from "lodash";

const POSTHOG_OPTIONS = (isProdEnv: boolean) => ({
  api_host: import.meta.env.VITE_APP_PUBLIC_POSTHOG_HOST,
  mask_all_text: true,
  mask_all_element_attributes: true,
  loaded: (ph: PostHog) => {
    if (!isProdEnv) {
      ph.opt_out_capturing(); // opts a user out of event capture
      ph.set_config({
        disable_session_recording: true,
        capture_pageleave: false,
        capture_pageview: false,
        capture_performance: false,
        autocapture: false,
      });
    }
  },
});

// initialize cookies
const cookies = new Cookies(null, {
  path: "/",
  domain: getCookieURL(),
});

const App: FC = () => {
  useLanguageSelector();
  const queryParams: MainQueryParams = useQueryParams();
  const navigation = useNavigate();
  const location = useLocation();
  const insuranceCalculatorRoute = isInsuranceCalculatorRoute();
  const insuranceCheckInRoute = isInsuranceCheckInRoute();
  const { getColor } = useColors();
  const {
    authenticated,
    user,
    admin,
    getUser,
    setUserToken,
    clearAuth,
    setInsuranceFlow,
    adminAuthenticate,
    clearAdminAuth,
  } = useAuthStore();
  const toast = useToast();
  const {
    getPartner,
    loaded,
    brand,
    redirectCalculator,
    gtmId,
    trengo_key,
    setExpoIp,
    setCheckInReturnUrl,
    hide_profession_page,
  } = usePartnerStore();
  const { title } = useTitleStore();
  const { initTrengo } = useTrengo();
  const UTM_PRAMS = queryParams._gl ? `_gl=${queryParams._gl}` : "";
  const isProdEnv = getEnvironment() === "production";
  const adminCookie = cookies.get(ADMIN_COOKIE_TOKEN);
  // setup user flow in auth store
  const setupInsuranceFlow = () => {
    if (insuranceCalculatorRoute) {
      setInsuranceFlow(InsuranceFlow.CALCULATOR);
    } else if (insuranceCheckInRoute) {
      setInsuranceFlow(InsuranceFlow.CHECKIN);
    } else {
      if (!location.pathname.includes("/500")) {
        setInsuranceFlow(InsuranceFlow.DEFAULT);
      }
    }
  };

  const navigateBasedOnScenario = async (user: UserType) => {
    try {
      setupInsuranceFlow();
      if (!user.profession && !insuranceCheckInRoute && !hide_profession_page) {
        navigation(
          insuranceCalculatorRoute
            ? "/insurance-calculator/profession"
            : "/profession"
        );
        return;
      } else {
        if (insuranceCheckInRoute) {
          if (["/", "/insurance-checkin"].includes(location.pathname)) {
            // navigate to available products if user has selected profession
            const checkinParams = omit(queryParams, [
              "external_id",
              "signature_created_at",
              "signature",
            ]);
            navigation(
              `/insurance-checkin-welcome?${new URLSearchParams(
                Object(checkinParams)
              ).toString()}`
            );
          }
          return;
        } else if (insuranceCalculatorRoute) {
          if (["/", "/insurance-calculator"].includes(location.pathname)) {
            // navigate to available products if user has selected profession
            navigation("/insurance-calculator/available-products");
          }
          return;
        } else {
          if (["/", "/welcome"].includes(location.pathname)) {
            navigation(
              queryParams.quote_id
                ? "/checkout/quotation?"
                : "/products/available-products?" + UTM_PRAMS
            );
          }
          return;
        }
      }
    } catch (error) {
      toast({
        title: String(error),
        variant: "solid",
        isClosable: true,
        status: "warning",
      });
    }
  };

  // Cookie authentication
  const getAdminCookieData = async () => {
    try {
      if (!admin && adminCookie) {
        await adminAuthenticate(adminCookie);
      }
    } catch (error) {
      clearAdminAuth();
      toast({
        title: String(error),
        variant: "solid",
        isClosable: true,
        status: "warning",
      });
    }
  };

  const fetchPartner = async () => {
    try {
      await getPartner();
    } catch (error) {
      toast({
        title: error as string,
        variant: "solid",
        isClosable: true,
        status: "warning",
      });
    }
  };

  const getUserDetails = async () => {
    try {
      await getUser();
    } catch (error) {
      toast({
        title: error as string,
        variant: "solid",
        isClosable: true,
        status: "warning",
      });
    }
  };

  useEffect(() => {
    fetchPartner();
    if (queryParams?.signature) {
      clearAuth();
      return;
    }
    if (authenticated) {
      void getUserDetails();
    }
    if (isProdEnv) {
      TagManager.initialize({
        gtmId: "GTM-W6RT8T6",
        dataLayer: {
          userId: user?.id,
        },
      });
    }

    setupInsuranceFlow();
  }, []);

  useEffect(() => {
    if (authenticated && user) {
      navigateBasedOnScenario(user);
    }
  }, [authenticated, user, queryParams]);

  useEffect(() => {
    if (redirectCalculator && insuranceCalculatorRoute) {
      setInsuranceFlow(InsuranceFlow.DEFAULT);
      navigation("/welcome");
    }
  }, [redirectCalculator]);

  useEffect(() => {
    authenticated && adminCookie && getAdminCookieData();
  }, [authenticated, adminCookie]);

  useEffect(() => {
    if (trengo_key) {
      initTrengo(trengo_key);
    }
  }, [trengo_key]);

  useEffect(() => {
    if (isProdEnv && gtmId) {
      TagManager.initialize({
        gtmId: gtmId,
        dataLayer: {
          userId: user?.id,
        },
      });
      if (queryParams.email && queryParams.userId) {
        TagManager.dataLayer({
          dataLayer: {
            event: "aliciaIdentifiers",
            encryptedUserId: queryParams.userId,
            hashedEmail: queryParams.email,
          },
        });
      }
    }
  }, [gtmId, queryParams.email, queryParams.userId]);

  useEffect(() => {
    const setUserAuth = async () => {
      try {
        // check and save expo_ip for user detail modal on mobile app (QA/STAGE)
        if (queryParams.expo_ip) {
          setExpoIp(queryParams.expo_ip);
        }
        // check and save returnURL for checkin flow
        if (queryParams.returnURL) {
          setCheckInReturnUrl(queryParams.returnURL);
        }
        // Check partner signature not exist and redirect to welcome page
        if (location.pathname === "/" && !queryParams.signature) {
          setInsuranceFlow(InsuranceFlow.DEFAULT);
          navigation("/welcome?" + UTM_PRAMS);
        }

        // Check partner signature and external id to get auth tokens
        if (
          !authenticated &&
          loaded &&
          queryParams.signature &&
          queryParams.external_id &&
          queryParams.signature_created_at
        ) {
          clearAuth();
          await setUserToken(queryParams);

          /*
           * This is for the case when a partner is using multiple platforms in one schema
           * Platform can be web or app
           * When the platform is web, we don't trigger deeplinks
           **/
          if (queryParams.platform) {
            cookies.set("platform", queryParams.platform, {
              path: "/",
              secure: true,
            });
            TagManager.dataLayer({
              dataLayer: {
                event: "platform",
                value: queryParams.platform,
              },
            });
          }
        }
      } catch (error) {
        toast({
          title: error as string,
          variant: "solid",
          isClosable: true,
          status: "warning",
        });
        clearAuth();
        navigation("/welcome?" + UTM_PRAMS);
      }
    };
    void setUserAuth();
  }, [authenticated, queryParams, loaded]);

  const overrides = extendTheme({
    ...chakraTheme,
    colors: {
      ...chakraTheme.colors,

      green: {
        50: "#dafdce",
        100: "#d2fdc4",
        200: "#cbfcba",
        300: "#c3fcb0",
        400: "#bcfba6",
        500: "#b4fb9c",
        600: "#a2e28c",
        700: "#90c97d",
        800: "#7eb06d",
        900: "#6c975e",
      },
      // TODO: change this color to custom one
      dropDownHover: getColor("accent-positive-1"),
    },
    components: {
      Radio: {
        baseStyle: {
          control: {
            borderColor: "gray.light",
            height: "16px",
            width: "16px",
          },
        },
      },
      Button: {
        variants: {
          primary: (props: any) => ({
            ...chakraTheme?.components?.Button?.variants?.outline(props),
            color: getColor("button-solid-text"),
            fill: getColor("button-solid-text"),
            stroke: getColor("button-solid-text"),
            backgroundColor: getColor("button-solid-background"),
            borderColor: getColor("button-solid-border"),
            _hover: {
              backgroundColor: getColor("button-solid-hover-background"),
              color: getColor("button-solid-hover-text"),
              borderColor: getColor("button-solid-hover-border"),
              stroke: getColor("button-solid-hover-text"),
              fill: getColor("button-solid-hover-text"),
              _disabled: {
                color: getColor("button-solid-text"),
                fill: getColor("button-solid-text"),
                stroke: getColor("button-solid-text"),
                backgroundColor: getColor("button-solid-background"),
                borderColor: getColor("button-solid-border"),
              },
            },
          }),
          outline: (props: any) => ({
            ...chakraTheme?.components?.Button?.variants?.outline(props),
            color: getColor("button-ghost-text"),
            fill: getColor("button-ghost-text"),
            stroke: getColor("button-ghost-text"),
            backgroundColor: "transparent",
            borderColor: getColor("button-ghost-border"),
            _hover: {
              backgroundColor: getColor("button-ghost-hover-background"),
              color: getColor("button-ghost-hover-text"),
              borderColor: getColor("button-ghost-hover-border"),
              stroke: getColor("button-ghost-hover-text"),
              fill: getColor("button-ghost-hover-text"),
              _disabled: {
                color: getColor("button-ghost-text"),
                fill: getColor("button-ghost-text"),
                stroke: getColor("button-ghost-text"),
                backgroundColor: "transparent",
                borderColor: getColor("button-ghost-border"),
              },
            },
          }),
          secondary: (props: any) => ({
            ...chakraTheme?.components?.Button?.variants?.outline(props),
            backgroundColor: "transparent",
            color: "black",
            _hover: {
              color: "white",
              backgroundColor: "black",
              borderColor: "brand.500",
            },
          }),
          disabled: (props: any) => ({
            ...chakraTheme?.components?.Button?.variants?.outline(props),
            color: "gray",
            border: 0,
            backgroundColor: "white",
            _hover: {
              borderColor: "brand.500",
            },
          }),
        },
      },
    },
    styles: {
      global: () => ({
        "*": {
          fontFamily: "primary",
        },
        html: {
          ".aov-review": {
            ".react-calendar": {
              position: "fixed",
            },
          },
        },
        dropDownHover: "rgba(150, 201, 255, 1)",
        ".react-date-picker__calendar--open": {
          zIndex: 4,
        },
      }),
    },
    textStyles: {
      primary: {
        fontFamily: brand?.font_primary_name,
      },
    },
  });

  const AppTheme = extendTheme(overrides);
  return (
    <HelmetProvider>
      <Box
        sx={{
          display: "flex",
          height: "100%",
          flexDirection: "column",
          font: brand?.font_primary,
        }}
      >
        <link rel="preload" href={brand?.font_primary} as="style"></link>
        <link rel="stylesheet" href={brand?.font_primary}></link>
        <Helmet>
          <title>{title ?? "Alicia insurance"}</title>
        </Helmet>
        {loaded ? (
          <PostHogProvider
            apiKey={import.meta.env.VITE_APP_PUBLIC_POSTHOG_KEY ?? ""}
            options={POSTHOG_OPTIONS(isProdEnv)}
          >
            <ChakraProvider theme={AppTheme}>
              <Routes />
            </ChakraProvider>
          </PostHogProvider>
        ) : (
          <></>
        )}
      </Box>
    </HelmetProvider>
  );
};

export default App;
