import { useMemo } from "react";
import qs from "qs";
import { useLocation } from "react-router-dom";

export const useQueryParams = () => {
  const location = useLocation();

  const queryParams: UseQueryParamsReturnType = useMemo(() => {
    const getQueryParams = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    });

    return getQueryParams;
  }, [location.search]);

  return queryParams;
};

export type UseQueryParamsReturnType = {
  [key: string]: any;
};
