import { FC, lazy, ReactElement, Suspense, useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import RootLoader from "features/shared/loader/root-loader";
import { useAuthStore } from "store";
import { useTitleStore } from "store/title";
import IdentityVerificationPage from "pages/private/checkout/identity-verification";
import { isCheckoutPage, isInsuranceCheckInRoute } from "@utils/getFlowPaths";
import QuestionLoader from "features/shared/loader/question-loader";

/**
 * lazy loaded routes are added here
 */

// public routes
const Welcome = lazy(() => import("pages/public/welcome"));
const Signin = lazy(() => import("pages/public/signin"));
const Confirmation = lazy(() => import("pages/public/confirmation"));
const Accounts = lazy(() => import("pages/public/accounts"));

// private routes
const Products = lazy(() => import("pages/private/products"));
const Profession = lazy(() => import("pages/public/profession"));
const Checkout = lazy(() => import("pages/private/checkout"));
const Result = lazy(() => import("pages/private/result"));
const CheckoutFailed = lazy(() => import("pages/private/checkout/failure"));
const InsuranceCalculatorWelcome = lazy(
  () => import("pages/public/insurance-calculator")
);
const InsuranceCheckInRoot = lazy(
  () => import("pages/public/insurance-checkin")
);
const InsuranceCheckInWelcome = lazy(
  () => import("pages/private/insurance-checkin/welcome")
);
const InsuranceCheckIn = lazy(() => import("pages/private/insurance-checkin"));
const InsuranceCheckInResult = lazy(
  () => import("pages/private/insurance-checkin/result")
);
const InsuranceCalculator = lazy(
  () => import("pages/private/insurance-calculator")
);
const Survey = lazy(() => import("pages/private/survey"));
const PaymentSuccess = lazy(() => import("pages/public/payment/success"));

// user routes
const User = lazy(() => import("pages/private/user"));

// error status routes
const InternalServerError = lazy(
  () => import("pages/error/internal-server-error")
);
const NotFound = lazy(() => import("pages/error/not-found"));
const InvoiceReminder = lazy(() => import("pages/public/reminder/invoice"));

export type RouteProps = {
  component: ReactElement;
  title?: string;
};

const PrivateRoute: FC<RouteProps> = ({ component, title }) => {
  const [loading, setLoading] = useState(true);
  const insuranceCheckinRoute = isInsuranceCheckInRoute();
  const isCheckoutRoute = isCheckoutPage();

  const { authenticated } = useAuthStore();
  const { setTitle } = useTitleStore();

  useEffect(() => {
    if (title) {
      setTitle(title);
    }
  }, [title]);

  useEffect(() => {
    if (authenticated) {
      setLoading(false);
    }
  }, [authenticated]);

  return loading ? (
    insuranceCheckinRoute ? (
      <QuestionLoader />
    ) : isCheckoutRoute ? (
      component
    ) : (
      <RootLoader />
    )
  ) : (
    component
  );
};

const AppRoutes: FC = () => {
  return (
    <Suspense fallback={<></>}>
      {/* The loader will be discussed.. It's better without it now */}
      <Routes>
        {/** public routes */}
        {/** NOTE: Added loading component to root path because we are redirecting after authentication check  */}
        <Route path="/" element={<RootLoader />} />
        <Route path="/welcome" element={<Welcome />} />
        <Route
          path="/insurance-calculator"
          element={<InsuranceCalculatorWelcome />}
        />
        <Route path="/insurance-checkin" element={<InsuranceCheckInRoot />} />
        <Route path="/sign-in" element={<Signin />} />
        <Route path="/confirmation" element={<Confirmation />} />
        <Route path="/accounts" element={<Accounts />} />
        <Route path="/invoice-reminder" element={<InvoiceReminder />} />
        <Route path="/payment/success" element={<PaymentSuccess />} />
        {/** private routes */}
        <Route
          path="/insurance-calculator/*"
          element={<PrivateRoute component={<InsuranceCalculator />} />}
        />
        <Route
          path="/insurance-checkin-welcome"
          element={<PrivateRoute component={<InsuranceCheckInWelcome />} />}
        />
        <Route path="/profession" element={<Profession />} />
        <Route
          path="/products/*"
          element={<PrivateRoute component={<Products />} />}
        />
        <Route
          path="/checkout/*"
          element={<PrivateRoute component={<Checkout />} />}
        />
        <Route
          path="/checkout/identity-verification"
          element={<PrivateRoute component={<IdentityVerificationPage />} />}
        />
        <Route
          path="/checkout/failure"
          element={<PrivateRoute component={<CheckoutFailed />} />}
        />
        {/* user routes */}
        <Route path="/user/*" element={<PrivateRoute component={<User />} />} />
        {/* all result related pages should be handled from here : Ex:- /checkout/success, /checkout/failure, /dropout  */}
        <Route
          path="/result"
          element={<PrivateRoute component={<Result />} />}
        />
        <Route
          path="/insurance-checkin-checkout/result"
          element={<PrivateRoute component={<InsuranceCheckInResult />} />}
        />
        <Route
          path="/survey/:slug/:productId/*"
          element={<PrivateRoute component={<Survey />} />}
        />
        <Route
          path="/insurance-checkin-checkout/*"
          element={<PrivateRoute component={<InsuranceCheckIn />} />}
        />
        {/** error routes */}
        <Route path="/500" element={<InternalServerError />} />
        <Route path="/404" element={<NotFound />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
};

export default AppRoutes;
