import { create } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";
import { TranslationLanguageType } from "../types/types";
import { LANGUAGE_STORE } from "../constants/constant";

const initialLanguageState = {
  language: "",
};

export const useLanguageStore = create(
  devtools(
    persist<TranslationLanguageType>(
      (set) => ({
        ...initialLanguageState,
        setLanguage: (data: string) => {
          try {
            set(() => ({
              language: data,
            }));
          } catch (err: any) {
            const error =
              err?.message || err?.data?.message || "Unexpected network error.";
            throw error;
          }
        },
        clearLanguage: () => {
          set(() => initialLanguageState);
        },
      }),
      {
        name: LANGUAGE_STORE,
        storage: createJSONStorage(() => localStorage),
      }
    )
  )
);
