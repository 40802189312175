import { useQueryParams } from "hooks/useQueryParams";
import { MainQueryParams } from "src/types";

export const isInsuranceCalculatorRoute = () => {
  const queryParams: MainQueryParams = useQueryParams();
  const insuranceCalculatorRoute =
    queryParams.insurance_calculator ??
    location.pathname.includes("insurance-calculator");
  return insuranceCalculatorRoute;
};

export const isInsuranceCheckInRoute = () => {
  const queryParams: MainQueryParams = useQueryParams();
  const insuranceCheckinRoute =
    queryParams.insurance_checkin ??
    (location.pathname.includes("insurance-checkin") ||
      location.pathname.includes("insurance-checkin-checkout"));
  return insuranceCheckinRoute;
};

export const isCheckoutPage = () => {
  return location.pathname.includes("checkout");
};
