import { getLocalPartner } from "@utils/getLocalPartner";

// Define the possible environments
type Environments = "development" | "qa" | "stage" | "production";
interface DomainData {
  partner: string; // The partner identifier
  environment: Environments; // Ensure environment is one of the valid keys
}

export const getPartnerSlug = (
  env?: string | "qa" | "stage" | "production"
): DomainData => {
  const host: string = window.location.host;
  const [partner] = host.split(".");

  return {
    partner: partner === "localhost:3000" ? getLocalPartner() : partner,
    environment: env ? env : "development",
  } as DomainData;
};
