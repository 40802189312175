import { Flex, Text, Tooltip } from "@chakra-ui/react";
import { rem } from "polished";
import { FC, useEffect, useState } from "react";
import { DeleteIcon } from "src/icons";
import { ChevronDownIcon, ChevronUpIcon } from "alicia-design-system";
import { useTranslation } from "react-i18next";
import { CartItemListItemType, CartItemListItemTypeEnum } from "src/types";
import { motion } from "framer-motion";
import { currencyFormatter } from "@utils/formatPrice";
import { useColors } from "hooks/useColors";
import { InfoOutlineIcon } from "@chakra-ui/icons";
import { useAuthStore } from "store/authentication";
import moment from "moment";

const CartProductCard: FC<CartProductCardProps> = ({
  name,
  amount,
  netPrice,
  currency = "EUR",
  listItems = [],
  onDelete,
  isFunnelStarted,
  cartItemId,
  isExpandedByDefault = false,
}) => {
  const { t } = useTranslation();
  const { getColor } = useColors();
  const { user_quote } = useAuthStore();

  const [isExpanded, setIsExpanded] = useState(isExpandedByDefault ?? false);
  const [isInitialSetup, setIsInitialSetup] = useState(false); // variable to make the card expanded by default only once

  useEffect(() => {
    // checking if the funnel is started and the card is not collapsed by the user before
    if (isFunnelStarted && !isInitialSetup) {
      setIsInitialSetup(true);
      setIsExpanded(true);
    }
  }, [listItems]);

  const Delete = DeleteIcon({
    fillColor: getColor("cart-card-delete-icon", "text") ?? "#006A70",
  });

  const container = {
    hidden: { x: -100 },
    show: {
      x: [-50, 10, 0],
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const item = {
    hidden: { x: -50 },
    show: { x: [-50, 10, 0], stiffness: 100 },
  };

  return (
    <Flex
      mt={rem(24)}
      pb={rem(20)}
      flexDirection="column"
      background={getColor("cart-card-background", "background-card")}
      borderRadius={rem(4)}
      pt={rem(20)}
      px={rem(16)}
      w="100%"
    >
      <Flex direction="column">
        <Flex
          justifyContent="space-between"
          borderBottom={`${rem(1)} solid ${getColor("cart-card-seperator")}`}
          pb={rem(16)}
          gap={4}
        >
          <Text
            fontWeight="bold"
            lineHeight={rem(24)}
            width="100%"
            sx={{
              wordWrap: "normal",
              hyphens: "auto",
            }}
            overflow="hidden"
            textOverflow={"ellipsis"}
            title={name}
            color={getColor("cart-card-text")}
          >
            {name}
          </Text>
          {!isFunnelStarted ? (
            !user_quote && onDelete ? (
              <Delete
                data-testid="delete-btn"
                fill={getColor("cart-card-delete-icon", "accent-negative-1")}
                onClick={() => onDelete(cartItemId)}
                _hover={{
                  fill: getColor(
                    "cart-card-delete-icon-hover",
                    "accent-negative-1"
                  ),
                  cursor: "pointer",
                }}
              />
            ) : null
          ) : (
            <>
              {listItems.length > 0 && (
                <>
                  {!isExpanded && (
                    <ChevronUpIcon
                      width={rem(20)}
                      height={rem(20)}
                      ml={rem(8)}
                      onClick={() => setIsExpanded(!isExpanded)}
                      _hover={{
                        stroke: getColor(
                          "cart-card-background",
                          "background-card"
                        ),
                      }}
                    />
                  )}
                  {isExpanded && (
                    <ChevronDownIcon
                      width={rem(20)}
                      height={rem(20)}
                      ml={rem(8)}
                      onClick={() => setIsExpanded(!isExpanded)}
                      _hover={{
                        stroke: getColor(
                          "cart-card-background",
                          "background-card"
                        ),
                      }}
                    />
                  )}
                </>
              )}
            </>
          )}
        </Flex>
      </Flex>
      {isExpanded && listItems && (
        <motion.div variants={container} initial="hidden" animate="show">
          {listItems.map((listItem) => (
            <motion.div variants={item} key={listItem.label}>
              <CartListItem listItem={listItem} currency={currency} />
            </motion.div>
          ))}
        </motion.div>
      )}
      <Flex
        width="100%"
        alignItems="center"
        justifyContent="space-between"
        mt={rem(16)}
      >
        {!Number(amount ?? "0.00") ? (
          <Text fontSize={rem(12)} color={getColor("cart-card-text")}>
            {t("common.calculating") ?? "Bereken je premie binnen 3 minuten"}
          </Text>
        ) : (
          <Flex w="100%" justifyContent="space-between">
            <Text
              fontSize={rem(12)}
              color={getColor("cart-card-text")}
              lineHeight={rem(24)}
            >
              {t("cart.total")}
            </Text>
            {netPrice && (
              <Flex flexDirection="column" gap={rem(12)}>
                <Text
                  fontSize={rem(12)}
                  color={getColor("cart-card-text")}
                  lineHeight={rem(24)}
                >
                  {t("common.gross")}
                </Text>
                <Flex alignItems="center" gap={rem(10)}>
                  <Text
                    fontSize={rem(12)}
                    color={getColor("cart-card-text")}
                    lineHeight={rem(24)}
                  >
                    {t("common.net")}
                  </Text>
                  <Tooltip
                    bg="black"
                    color="white"
                    label={t("common.netTooltip")}
                    fontSize={rem(12)}
                    p={4}
                    placement="auto"
                  >
                    <InfoOutlineIcon
                      color={getColor("cart-card-text")}
                      fontSize={rem(14)}
                      cursor="pointer"
                    />
                  </Tooltip>
                </Flex>
              </Flex>
            )}
            <Flex flexDirection="column" gap={rem(12)}>
              <Flex alignItems="center" justifyContent="flex-end">
                <Text
                  fontWeight="bold"
                  color={getColor("cart-card-text")}
                  fontSize={rem(12)}
                  lineHeight={rem(24)}
                >
                  {currencyFormatter(
                    String(amount ?? "0.00"),
                    currency ?? "EUR"
                  )}
                </Text>
                <Text
                  ml={rem(4)}
                  color={getColor("cart-card-text")}
                  fontSize={rem(12)}
                  lineHeight={rem(24)}
                >
                  {"  "}
                  {t("common.perMonthShort")}
                </Text>
              </Flex>
              {netPrice && (
                <Flex alignItems="center" justifyContent="flex-end">
                  <Text
                    fontWeight="bold"
                    color={getColor("cart-card-text")}
                    fontSize={rem(12)}
                    lineHeight={rem(24)}
                  >
                    {currencyFormatter(
                      String(netPrice ?? "0.00"),
                      currency ?? "EUR"
                    )}
                  </Text>
                  <Text
                    ml={rem(4)}
                    color={getColor("cart-card-text")}
                    fontSize={rem(12)}
                    lineHeight={rem(24)}
                  >
                    {"  "}
                    {t("common.perMonthShort")}
                  </Text>
                </Flex>
              )}
            </Flex>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

const CartListItem: FC<CartListItemProps> = ({
  listItem,
  currency = "EUR",
}) => {
  const { getColor } = useColors();

  const formatDateString = (date: string) => {
    return moment(new Date(date)).format("DD-MM-YYYY");
  };

  return (
    <Flex
      flexDirection="column"
      alignItems="start"
      mt={rem(8)}
      borderBottom={`${rem(1)} solid ${getColor("cart-card-seperator")}`}
      pb={rem(12)}
    >
      <Flex justifyContent="space-between" w="100%" mt={rem(8)} gap={rem(4)}>
        <Text fontSize={rem(12)} color={getColor("cart-card-text")} maxW="80%">
          {listItem.label}
        </Text>
        {listItem.value !== undefined && (
          <Text
            pr={rem(8)}
            textAlign="right"
            fontSize={rem(12)}
            fontWeight="bold"
            color={getColor("cart-card-text")}
          >
            {listItem.type === CartItemListItemTypeEnum.MONEY && (
              <>
                {currencyFormatter(
                  String(Number(listItem.value).toFixed(2) ?? "0.00"),
                  currency ?? "EUR"
                )}
              </>
            )}
            {listItem.type === CartItemListItemTypeEnum.DATE && (
              <>{formatDateString(listItem.value)}</>
            )}

            {listItem.type === CartItemListItemTypeEnum.TEXT && (
              <>{listItem.value}</>
            )}
          </Text>
        )}
      </Flex>
    </Flex>
  );
};

interface CartListItemProps {
  listItem: CartItemListItemType;
  currency?: string;
}

interface CartProductCardProps {
  name: string;
  amount: number;
  currency?: string;
  listItems?: CartItemListItemType[];
  onDelete?: (id: string) => void;
  isFunnelStarted?: boolean;
  cartItemId: string;
  isExpandedByDefault?: boolean;
  netPrice?: string;
}

export default CartProductCard;
