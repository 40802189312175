import {
  CartItemType,
  ProductEligibilityResultType,
  ProductType,
} from "src/types";

export const addDataLayerEvent = (
  event: string,
  label: string,
  action: string | unknown,
  rest?: any
) => {
  window.dataLayer = window.dataLayer || [];
  return window.dataLayer.push({
    event: event,
    label: label,
    action: action,
    ...rest,
  });
};
export const clearDataLayerEcommerceEvent = () => {
  window.dataLayer = window.dataLayer || [];
  return window.dataLayer.push({ ecommerce: null });
};
export const formatItemForDataLayer = (item: ProductType) => {
  const formattedItem = {
    item_id: item?.id || "",
    item_name: item?.name || "",
    price: item?.price || 0,
  };
  return formattedItem;
};
export const formatItemListForDataLayer = (items: CartItemType[]) => {
  return items.map(({ insurance_product }) => ({
    item_id: insurance_product?.id || "",
    item_name: insurance_product?.name || "",
    price: insurance_product?.price || 0,
  }));
};
export const formatProductItemListForDataLayer = (items: ProductType[]) => {
  return items.map((item) => ({
    item_id: item?.id || "",
    item_name: item?.name || "",
    price: item?.price || 0,
  }));
};

export const calculateAmount = (items: CartItemType[]) => {
  let amount = 0;
  items.forEach((item) => {
    amount =
      amount +
      (Number(item?.insurance_product?.price)
        ? Number(item?.insurance_product?.price)
        : 0);
  });
  return amount;
};

export const calculateProductTotal = (
  products?: ProductEligibilityResultType[]
) => {
  let amount = 0;
  (products ?? []).forEach((item) => {
    amount = amount + (Number(item?.price) ? Number(item?.price) : 0);
  });
  return amount;
};

export const Events = {
  button_click: "funnel_button_click",
  select: "funnel_select",
  add_shipping_info: "add_shipping_info",
  add_payment_info: "add_payment_info",
  quiz: "quiz",
  search: "funnel_search",
  profession_select: "funnel_profession_select",
  select_item: "select_item",
  add_to_cart: "add_to_cart",
  view_cart: "view_cart",
  view_item_list: "view_item_list",
  remove_from_cart: "remove_from_cart",
  begin_checkout: "begin_checkout",
  purchase: "purchase",
  dropout: "dropout",
  support: "support",
};
